import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import type { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import { sendAnalyticsOfItem } from '~source/core/services/e-commerce/e-commerce';
import PriceDisplay from '~source/ui/components/atoms/price-display/price-display';
import useImageUrl from '~source/ui/hooks/formatter/useImageUrl/useImageUrl';
import maprange from '~source/ui/utils/math/maprange';
import { createProductDetailUrl } from '~source/ui/utils/urls/product-detail-url';
import $ from './product-box.module.scss';

type Props = {
  product: ProductTeaser;
  carousel?: boolean;
  index?: number;
};

export default function ProductBox({
  product,
  carousel = false,
  index,
}: Props) {
  const imageUrl = useImageUrl(product.primaryImage);
  const requestRef = useRef<number | null>(null);
  const refBox = useRef<HTMLAnchorElement | null>(null);
  const [size, setSize] = useState(100);
  const [mappedSize, setMappedSize] = useState(0);

  const updateSize = () => {
    const width = 340;
    const box = refBox.current;
    if (!box) return;

    const offset = box.getBoundingClientRect();
    const center = Math.abs(
      offset.left + width / 2 - document.body.clientWidth / 2,
    );

    const newSize = maprange(center, 0, 700, 100, 60, true);
    setSize(newSize);
    setMappedSize(maprange(newSize, 0, 100, 100, 0));
    requestRef.current = requestAnimationFrame(updateSize);
  };

  const handleAnalytics = () =>
    sendAnalyticsOfItem({
      product,
      eventType: 'select_item',
      index,
    });

  useEffect(() => {
    if (!carousel) return;

    requestRef.current = requestAnimationFrame(updateSize);
    // eslint-disable-next-line consistent-return
    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageElement = (
    <div
      style={
        carousel
          ? {
              width: (250 / 100) * size,
              height: (325 / 100) * size,
              padding: `${mappedSize}% ${mappedSize * 0.5}% 0`,
            }
          : {
              width: '100%',
              height: '100%',
            }
      }
    >
      <Image
        alt={product.primaryImage.name}
        className={cx($.thumb, !carousel && $.thumbIsGrid)}
        src={imageUrl}
        width={250}
        height={280}
      />
    </div>
  );

  let { displayValue } = product;
  if (carousel) {
    const displayValueLength = 18;
    displayValue =
      displayValue.length > displayValueLength
        ? `${displayValue.substring(0, displayValueLength - 3)}...`
        : displayValue;
  }

  const textElement = (
    <>
      <h2 className={$.title}>{displayValue}</h2>
      {product.price.original && (
        <div className={$.price}>
          <PriceDisplay price={product.price.original} />
        </div>
      )}
    </>
  );

  return (
    <Link
      ref={refBox}
      className={cx($.wrapper, carousel && $.wrapperIsCarousel)}
      href={createProductDetailUrl(product.slug, product.productId)}
      onClick={handleAnalytics}
    >
      {imageElement}
      <div className={$.content}>{textElement}</div>
    </Link>
  );
}
